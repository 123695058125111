import React from "react"
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {getCurrentUser} from '../../services'

const default_bg_image = 'https://media.istockphoto.com/photos/closeup-shot-of-an-unrecognisable-man-exercising-with-a-barbell-in-a-picture-id1369575748'
const add_bg_image = 'https://media.istockphoto.com/photos/green-plus-signs-on-the-green-background-points-and-prize-concept-picture-id1287871851'


const QUERY_MY_PROGRAMS = gql`
  query getMyPrograms($input: ProgramInput) {
  programs(input: $input) {
    id
    title
    description
    image
    sessions {
      id
    }
  }
}
`

interface ProgramsSectionProps {
  title?: string
}

const ProgramsSection = ({title = "My Programs"} : ProgramsSectionProps) => {

  const {data, loading, error } = useQuery(QUERY_MY_PROGRAMS, {variables: {input: { creatorId: getCurrentUser().id}}})
  const navigate = useNavigate()

  if(loading) {
    return <Box sx={{display: 'flex', flexDirection: 'column'}}>
    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
      <Box sx={{height: '30px', width: '200px', backgroundColor: 'lightgrey', marginRight: '10px', borderRadius: 2}} />
    </div>

    <Box sx={{display: 'flex', margin: '12px 0', overflowX: 'overlay'}}>
      <Box sx={{height: '200px', width: '150px', backgroundColor: 'lightgrey', marginRight: '20px', borderRadius: 2}} />
      <Box sx={{height: '200px', width: '150px', backgroundColor: 'lightgrey', marginRight: '20px', borderRadius: 2}} />
      
    </Box>
  </Box>
  }

  if (error) {
    return <p>error</p>
  }

  const handleProgramClick = (program : any)=>{ 
    navigate(`/p/program/${program.id}`)}

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant="h6" component="div" >
          {title}
        </Typography>
        <div style={{cursor: 'pointer'}} onClick={()=>{ navigate(`/w/create`) }}><AddBoxOutlinedIcon /></div>
      </div>

      <Box sx={{display: 'flex', margin: '12px 0', overflowX: 'overlay'}}>
        {data && data.programs.map((program: any) =>{
          const bg_url = program.image ?? default_bg_image

          return (
          <Button key={program.id} sx={{flexDirection:"column", flexShrink: 0,height: '200px', width: '150px', marginRight: '12px',color: 'white', backgroundSize: 'cover', backgroundImage: `url(${bg_url})`}} variant="text"
          onClick={()=>{handleProgramClick(program)}}>
            <Typography variant="h6" component="div" fontSize={"1em"} fontWeight={"bold"} sx={{backgroundColor:'black'}}>
              {program.title}
            </Typography>
          </Button>
        )})
       }
       <Button key={'new  program'} sx={{flexShrink: 0,height: '200px', width: '150px', marginRight: '12px',color: 'white', backgroundSize: 'cover',backgroundColor:"#1399fc", backgroundImage: `url(${add_bg_image})`, ":hover": {backgroundColor: "#0a4d86"}}} variant="text"
        onClick={()=>{ navigate(`/w/create`) }}>
          <Typography variant="h6" component="div" fontWeight={'bold'}>
            Create +
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default ProgramsSection