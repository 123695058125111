import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TryIcon from '@mui/icons-material/Try';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { Button } from '@mui/material';
import AiChat from '../organisms/AiChat';
import { getCurrentUser } from '../../services'
import { useContext } from 'react';
import {AiChatContext} from '../organisms/Layout'
import FeedbackForm from '../organisms/FeedbackForm';

const BottomNav = () => {
  const [openSubmitForm, setOpenSubmitForm] = React.useState(false);
  const {aiChatOpen, aiChatCommand, setAiChatCommand} = useContext(AiChatContext);
  const isUserLoggedIn = !!getCurrentUser()

  const navigate = useNavigate()

  return <div >
      {aiChatOpen ? <div style={{
        display: 'block',
        position: 'fixed',
        bottom: 0,
        right: '2px',
        marginBottom: '19px',
        width: '85%',
        height: '90%',
        zIndex: 200
      }}>
        <AiChat 
          aiChatCommand={aiChatCommand}
          onCloseChat={()=> setAiChatCommand(undefined)}
        />
      </div> : null}

      {openSubmitForm ? <FeedbackForm open={openSubmitForm} setDialogOpen={setOpenSubmitForm} /> : null}

      {isUserLoggedIn ? <Box sx={{display: 'flex',position: 'fixed', justifyContent: 'space-between', padding: '12px 24px', borderTop: '1px solid lightgrey', bottom: 0, width: '100%', backgroundColor: 'white', zIndex: 1000}}>
          <Button color='inherit'><SearchIcon onClick={()=>{ navigate('/s/explore-sessions')}} /></Button>
          <Button color='inherit'><HomeIcon onClick={()=>{ navigate('/p/')}} /></Button>
          <Button color='inherit'><TryIcon onClick={()=>{ 
            setAiChatCommand(!aiChatOpen)
          }} />
          </Button>
          <Button color='inherit'><ThumbUpAltIcon onClick={()=>{ setOpenSubmitForm(true)}} /></Button>
          <Button color='inherit'><MoreVertIcon onClick={()=>{ navigate('/p/edit')}} /></Button>
        </Box> : null}
    </div>
}

export default BottomNav