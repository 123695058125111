import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import './Beta.css';
import logoSrc from '../../img/logo.png';

const SUBMIT_BETA_REQUEST = gql`
  mutation Mutation($input: BetaRequestInput!) {
    submitBetaRequest(input: $input) {
      id
      name
      email
    }
  }
`;

const BetaRequestForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [submitBetaRequest] = useMutation(SUBMIT_BETA_REQUEST);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await submitBetaRequest({ variables: { input: { name, email, message } } });
      setSubmitted(true);
    } catch (error: any) {
      console.error('Beta request error:', error.message);
      if (error.message?.includes('beta request')) {
        setErrorMessage(error.message);
        setSubmitted(true); // Mark as submitted to show the thank-you container
      }
    }
  };

  if (submitted) {
    return (
      <div className="thank-you-container">
        <div className="thank-you-card">
          <img src={logoSrc} alt="App Logo" className="app-logo" />
          <div className="thank-you-message">
            {errorMessage ? (
              <div>
                {errorMessage}
              </div>
            ) : (
              <>
                Thank you, {name}! Your request has been submitted. 
                <br />
                We will contact you at {email} soon.
              </>
            )}
          </div>
          <button className="marketing-button" onClick={() => window.location.href = 'https://serioustrainer.co.uk'}>
            Return to Home Page
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="beta-request-container">
      <div className="welcome-section">
        <img src={logoSrc} alt="App Logo" className="app-logo" />
        <h1>Welcome to Serious Trainer Beta Access</h1>
        <p>We're excited to have you join our beta program! Please fill out the form below to request access. We look forward to your feedback and hope you enjoy using our app.</p>
      </div>
      <form className="beta-request-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            Name:
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          </label>
        </div>
        <div className="form-group">
          <label>
            Email:
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </label>
        </div>
        <div className="form-group">
          <label>
            Message (optional):
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
          </label>
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default BetaRequestForm;