import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './PasswordReset.css';
import logoSrc from '../../img/logo.png';

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($updatePasswordInput2: UpdatePasswordInput!) {
    updatePassword(input: $updatePasswordInput2)
  }
`;

const PasswordResetForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get('id');
  const resetToken = searchParams.get('tkn');
  const [newPassword, setNewPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updatePassword({ variables: { updatePasswordInput2: { id, newPassword, resetToken } } });
      setSubmitted(true);
    } catch (error) {
      console.error('Error updating password:', error);
      setError('Failed to reset password. Please try again.');
    }
  };

  if (submitted) {
    return (
      <div className="thank-you-container">
        <div className="thank-you-card">
          <img src={logoSrc} alt="App Logo" className="app-logo" />
          <div className="thank-you-message">
            Thank you! Your password has been reset successfully.
          </div>
          <button className="marketing-button" onClick={() => navigate('/login')}>
            Go to Login Page
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="password-reset-container">
      <div className="welcome-section">
        <img src={logoSrc} alt="App Logo" className="app-logo" />
        <h1>Password Reset</h1>
        <p>Please enter your new password below.</p>
      </div>
      {error && <div className="error-message">{error}</div>}
      <form className="password-reset-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            New Password:
            <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
          </label>
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default PasswordResetForm;