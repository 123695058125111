import React from "react"
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {getCurrentUser} from '../../services'
import { useContext } from 'react';
import {AiChatContext} from '../organisms/Layout'

const default_bg_image = 'https://media.istockphoto.com/photos/closeup-shot-of-an-unrecognisable-man-exercising-with-a-barbell-in-a-picture-id1369575748'
const add_bg_image = 'https://media.istockphoto.com/photos/green-plus-signs-on-the-green-background-points-and-prize-concept-picture-id1287871851'


const QUERY_MY_SESSIONS = gql`
query getMySessions($input: SessionInput) {
  sessions(input: $input) {
    id
    title
    image
    aiGenerated
    createdAt
  }
}
`

interface SessionsSectionProps {
  title?: string
}

const SessionsSection = ({title = "My Sessions"} : SessionsSectionProps) => {
  const {setAiChatCommand} = useContext(AiChatContext);
  const {data, loading, error } = useQuery(QUERY_MY_SESSIONS, {variables: {input: { creatorId: getCurrentUser().id}}})
  const navigate = useNavigate()

  if(loading) {
    return <Box sx={{display: 'flex', flexDirection: 'column'}}>
    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
      <Box sx={{height: '30px', width: '200px', backgroundColor: 'lightgrey', marginRight: '10px', borderRadius: 2}} />
    </div>

    <Box sx={{display: 'flex', margin: '12px 0', overflowX: 'overlay'}}>
      <Box sx={{height: '200px', width: '150px', backgroundColor: 'lightgrey', marginRight: '20px', borderRadius: 2}} />
      <Box sx={{height: '200px', width: '150px', backgroundColor: 'lightgrey', marginRight: '20px', borderRadius: 2}} />
      
    </Box>
  </Box>
  }

  if (error) {
    return <p>error</p>
  }

  // sort based on createdAt - the leatest will be first
  const sessionsSorted = data.sessions.sort((a: any, b: any) => { return a - b }) 

  const handleSessionClick = (session : any)=>{ 
    navigate(`/p/session/${session.id}`)}

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant="h6" component="div" >
          {title}
        </Typography>
        <div style={{cursor: 'pointer'}} onClick={()=>{ setAiChatCommand('session_generator') }}><AddBoxOutlinedIcon /></div>
      </div>

      <Box sx={{display: 'flex', margin: '12px 0', overflowX: 'overlay'}}>
        {sessionsSorted.length ? sessionsSorted.map((session: any) =>{
          const bg_url = session.image ?? default_bg_image

          return (
          <Button key={session.id} sx={{flexDirection:"column", flexShrink: 0,height: '200px', width: '150px', marginRight: '12px',color: 'white', backgroundSize: 'cover', backgroundImage: `url(${bg_url})`}} variant="text"
          onClick={()=>{handleSessionClick(session)}}>

            {session.aiGenerated && <Box sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  borderRadius: '5px',
                  padding: '2px',
                  marginBottom: '15px',
                  fontSize: '10px',
            }}> AI Generated </Box>}
            <Typography variant="h6" component="div" fontSize={"1em"} fontWeight={"bold"} sx={{backgroundColor:'black'}}>
              {session.title}
            </Typography>
          </Button>
        )})
       : ""}
       <Button key={'new  session'} sx={{flexShrink: 0,height: '200px', width: '150px', marginRight: '12px',color: 'white', backgroundSize: 'cover',backgroundColor:"#1399fc", backgroundImage: `url(${add_bg_image})`, ":hover": {backgroundColor: "#0a4d86"}}} variant="text"
        onClick={()=>{ 
          // navigate(`/w/create`) 
          setAiChatCommand('session_generator')
          }}>
          <Typography variant="h6" component="div" fontWeight={'bold'}>
            Create +
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default SessionsSection