import React from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useForm, Controller } from "react-hook-form";
import gql from "graphql-tag"
import { useMutation } from "@apollo/client";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const MUTATION_ADD_FEEDBACK = gql`
mutation AddFeedback($input: FeedbackInput!) {
  submitFeedback(input: $input) {
    id
  }
}
`


interface FormProps {
  open: boolean,
  setDialogOpen: (a: any) => void,
}

const FeedbackForm = ({ open, setDialogOpen } : FormProps) => {
  const [rating, setRating] = React.useState<'good' | 'bad' | undefined>()
  const [addFeedback, {data, loading, error}] = useMutation(MUTATION_ADD_FEEDBACK)
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      feature: "",
      feedback: ""
    }
  });

  const onSubmit = (data : any) => {
    addFeedback({
      variables: {
        "input": {
          ...data,
          rating
        }
      }
    })
    .catch((e)=>{
      console.log(e)
    })
    .finally(()=>{
      setDialogOpen(false)
    })
  };

  return (
    <div>
       <Modal
        open={open}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box 
          sx={style} 
          className="rounded"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2" className="pb-6" style={{marginBottom: '16px'}}>
              Submit your feedback
            </Typography>

            <Box
              sx={{marginBottom: '16px'}}
            >
              {/* feature */}
              <InputLabel id="feature-select-label">Feature</InputLabel>
              <Controller
                control={control}
                name="feature"
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId="feature-select-label"
                    id="feature-select"
                    defaultValue="general"
                    label="feature"
                    onChange={onChange}
                    style={{width: '100%'}}
                  >
                    <MenuItem value="general">General</MenuItem>
                    <MenuItem value="chatbot">AI chatbot</MenuItem>
                    <MenuItem value="create workouts">Create workouts</MenuItem>
                    <MenuItem value="create programs">Create programs</MenuItem>
                    <MenuItem value="body-session log">Body/Session log</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                )}
              />
            </Box>

            <Box
              sx={{marginBottom: '16px'}}
            >
              <InputLabel id="Rating-select-label">Rating</InputLabel>
              <Button
                variant={rating === "good" ? "contained" : "outlined"}
                onClick={()=>setRating("good")}
                sx={{marginRight: '8px'}}
              >
                <ThumbUpAltIcon color={rating === "good" ? "inherit" : "disabled"}/>
              </Button>
              <Button
                variant={rating === "bad" ? "contained" : "outlined"}
                onClick={()=>setRating("bad")}
              >
                <ThumbDownAltIcon color={rating === "bad" ? "inherit" : "disabled"}/>
              </Button>
            </Box>
  
            {/* description */}
            <Box sx={{marginBottom: '16px'}}>
              <InputLabel id="feedback-select-label">Feedback</InputLabel>
              <TextareaAutosize
                aria-label="feedback"
                minRows={3}
                placeholder="What would you like to leave feedback for?"
                className="w-full mb-6"
                style={{width: '100%'}}
                autoFocus={true}
                {...register("feedback")}
              />
            </Box>
            
            <div className="flex">
              <div className="flex flex-1 justify-end">
               
                <Button 
                  variant="outlined"
                  color="error"
                  onClick={()=>setDialogOpen(false)}
                  sx={{marginRight: '8px'}}
                >
                  <span className="lowercase">cancel</span>
                </Button>
                <Button 
                  variant="contained"
                  type="submit"
                  className="mr-8"
                >
                  <span className="lowercase">Submit</span>
                </Button>
              </div>
            </div>
        </Box>
      </Modal>
    </div>
  )
}

export default FeedbackForm